import JwtService from './jwt.service'
import {message, Modal} from "ant-design-vue"
import store from "../store"
import router from "../router"
import {LOGOUT} from "../store/actions.type"

const ApiService = {
  setHeaders() {
    return {
      'Authorization': `Bearer ${JwtService.getToken()}`,
      'Content-Type': 'application/json',
    }
  },

  async handleResponse(response) {
    let responseJson;

    try {
      responseJson = await response.json();
    } catch (error) {
      responseJson = null;
    }

    if (response.ok) {
      return responseJson;
    }

    if (responseJson?.type === "NEED_UPGRADE") {
      store.commit("billing/setNeedUpgradeModal", {
        show: true,
        actionName: responseJson?.data?.actionName,
        isPlusAddon: responseJson?.data?.isPlusAddon
      });
      throw new Error(responseJson?.message);
    }

    const errorMessage = responseJson?.name === "MoleculerClientError" ? responseJson?.message : 'Something went wrong. Try again or chat with support!';

    switch (response.status) {
      case 401:
        handleUnauthorized();
        break;
      case 404:
        handleNotFound(responseJson?.type, errorMessage);
        break;
      default:
        handleOtherErrors(responseJson?.type, errorMessage);
    }

    throw new Error(response.statusText);
  },

  async get(resource) {
    const response = await fetch(`${resource}`, {
      headers: this.setHeaders()
    });
    return this.handleResponse(response);
  },

  async post(resource, params = {}) {
    const response = await fetch(`${resource}`, {
      headers: this.setHeaders(),
      method: 'POST',
      body: JSON.stringify(params),
    });
    return this.handleResponse(response);
  },

  async update(resource, params = {}) {
    const response = await fetch(`${resource}`, {
      method: 'PATCH',
      headers: this.setHeaders(),
      body: JSON.stringify(params),
    });
    return this.handleResponse(response);
  },

  async destroy(resource) {
    const response = await fetch(`${resource}`, {
      method: 'DELETE',
      headers: this.setHeaders(),
    });
    return this.handleResponse(response);
  },

  async filePost(resource, formData) {
    const response = await fetch(`${resource}`, {
      headers: {'Authorization': `Bearer ${JwtService.getToken()}`},
      method: 'POST',
      body: formData,
    });
    return this.handleResponse(response);
  },
};

function handleUnauthorized() {
  Modal.warning({
    title: 'Session Expired',
    content: 'Your session has expired. Please log in again.',
    okText: "Log In",
    centered: true,
    async onOk() {
      if (router.currentRoute.name !== 'Login') {
        await store.dispatch(`auth/${LOGOUT}`);
      }
    },
  });
}

function handleNotFound(errorType, errorMessage) {
  displayErrorMessage(errorMessage);

  if (errorType === "NEED_REDIRECT") {
    router.push({name: 'NotFound'});
  }
}

function handleOtherErrors(errorType, errorMessage) {
  if (errorType === "FILE_HAS_REFS") return;

  displayErrorMessage(errorMessage);
}

function displayErrorMessage(errorMessage) {
  message.error(errorMessage);
}

export default ApiService;
