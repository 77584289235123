<template>
  <div class="pt-7">

    <div class="px-10">
      <h3 class="flex items-center mb-8 text-character_gray-400">
        <span class="font-light text-2xl">Appearance</span>
      </h3>
    </div>

    <div class="px-10 space-y-8">
      <div class="flex space-x-8">
        <div v-if="isCta || isInline" class="flex items-center space-x-3">
          <span class="font-light text-lg">Size:</span>
          <a-select v-model="size"
                    size="large"
                    style="width: 144px;"
          >
            <a-select-option value="lg">
              Large button
            </a-select-option>
            <a-select-option value="sm">
              Small button
            </a-select-option>
          </a-select>
        </div>

        <div v-if="isSideButton" class="flex items-center space-x-3">
          <span class="font-light text-lg">Shape:</span>
          <a-select v-model="shape"
                    size="large"
                    style="width: 144px;"
          >
            <a-select-option value="text">
              Classic button
            </a-select-option>
            <a-select-option value="icon">
              Round
            </a-select-option>
          </a-select>
        </div>

        <div v-if="isSideButton" class="flex items-center space-x-3">
          <span class="font-light text-lg">Button position:</span>
          <a-select v-model="position"
                    size="large"
                    style="width: 350px;"
          >
            <a-select-option value="right-top">
              On the right side, at the top
            </a-select-option>
            <a-select-option value="right-middle">
              On the right side, in the middle
            </a-select-option>
            <a-select-option value="right-bottom">
              On the right side, at the bottom
            </a-select-option>
            <a-select-option value="left-top">
              On the left side, at the top
            </a-select-option>
            <a-select-option value="left-middle">
              On the left side, in the middle
            </a-select-option>
            <a-select-option value="left-bottom">
              On the left side, at the bottom
            </a-select-option>
            <a-select-option value="middle-bottom">
              At the bottom of the screen, in the middle
            </a-select-option>
          </a-select>
        </div>

        <div v-if="isCta" class="flex items-center space-x-3">
          <span class="font-light text-lg">Button align:</span>
          <a-select v-model="align"
                    size="large"
                    style="width: 350px;"
          >
            <a-select-option value="flex-start">
              On the left side of the block
            </a-select-option>
            <a-select-option value="center">
              In the middle of the block
            </a-select-option>
            <a-select-option value="flex-end">
              On the right side of the block
            </a-select-option>
          </a-select>
        </div>

      </div>

      <div v-if="isCta || isInline" class="flex items-center space-x-3">
        <div class="flex items-center space-x-1" style="margin-top: 28px;">
          <span class="font-light text-lg">Margins (px)</span>
          <a-tooltip>
            <template slot="title">
              Add margins between the button and other elements on your site.
            </template>
            <a-icon type="info-circle" class="icon-info"/>
          </a-tooltip>
        </div>

        <div class="flex items-center space-x-5 number-inputs">
          <div v-if="isCta" class="space-y-2">
            <div class="font-light text-base leading-tight">Top</div>
            <a-input-number size="large"
                            v-model="marginTop"
            />
          </div>
          <div v-if="isCta" class="space-y-2">
            <div class="font-light text-base leading-tight">Bottom</div>
            <a-input-number size="large"
                            v-model="marginBottom"
            />
          </div>
          <div class="space-y-2">
            <div class="font-light text-base leading-tight">Left</div>
            <a-input-number size="large"
                            v-model="marginLeft"
            />
          </div>
          <div class="space-y-2">
            <div class="font-light text-base leading-tight">Right</div>
            <a-input-number size="large"
                            v-model="marginRight"
            />
          </div>
        </div>
      </div>


    </div>

    <a-divider :dashed="true" style="margin: 40px 0 28px; border-color: var(--color-neutral-gray-300)"/>

    <div class="px-10">
      <h3 class="flex items-center mb-8 text-character_gray-400">
        <span class="font-light text-2xl">Button Style</span>
      </h3>
    </div>

    <div class="px-10 space-y-8">

      <div v-if="shape === 'text'" class="flex items-center space-x-8">
        <div class="flex items-center space-x-3">
          <span class="font-light text-lg" style="margin-top: -21px;">Button text:</span>
          <VTextArea :max-length="30" :count-typed-symbols="text.length">
            <a-input placeholder="Leave feedback"
                     size="large"
                     :maxLength="30"
                     v-model="text"
                     style="width: 244px; font-weight: 700;"
            />
          </VTextArea>
        </div>

        <div v-if="shape === 'text'" class="flex items-center space-x-3" style="margin-top: -21px;">
          <a-switch
              class="switch_positive-color"
              checked-children="On"
              un-checked-children="Off"
              v-model="hasIcon"
          />
          <div class="flex items-center space-x-1">
            <span class="font-light text-base">Show prefix icon</span>
            <a-tooltip>
              <template slot="title">
                Allow prefix icon to be shown.
              </template>
              <a-icon type="info-circle" class="icon-info"/>
            </a-tooltip>
          </div>
        </div>

        <div v-if="isSideButton && shape === 'text'" class="flex items-center space-x-3" style="margin-top: -21px;">
          <a-switch
              class="switch_positive-color"
              checked-children="On"
              un-checked-children="Off"
              v-model="isClosable"
          />
          <div class="flex items-center space-x-1">
            <span class="font-light text-base">Show close icon</span>
            <a-tooltip>
              <template slot="title">
                Allow hide the button.
              </template>
              <a-icon type="info-circle" class="icon-info"/>
            </a-tooltip>
          </div>
        </div>
      </div>

      <div :class="[{'flex items-center space-x-6': shape === 'icon'}, {'space-y-10': shape === 'text'}]">

        <div class="flex items-center space-x-3">
          <span class="flex-none font-light text-base" :style="labelColorStyle">White:</span>
          <VEmbedButtons :btn-list="styles.white"
                         :current-style="currentStyle"
                         :shape="shape"
                         :is-closable="isClosable"
                         :has-icon="hasIcon"
                         :size="size"
                         :value="text"
                         @doClickButton="onClickButton"
          />
        </div>

        <div class="flex items-center space-x-3">
          <span class="flex-none font-light text-base" :style="labelColorStyle">Color:</span>
          <VEmbedButtons :btn-list="styles.color"
                         :current-style="currentStyle"
                         :shape="shape"
                         :is-closable="isClosable"
                         :has-icon="hasIcon"
                         :size="size"
                         :value="text"
                         @doClickButton="onClickButton"
          />
        </div>

        <div class="flex items-center space-x-3">
          <span class="flex-none font-light text-base" :style="labelColorStyle">Custom:</span>
          <VEmbedButtons :btn-list="styles.custom"
                         :current-style="currentStyle"
                         :shape="shape"
                         :is-closable="isClosable"
                         :has-icon="hasIcon"
                         :size="size"
                         :value="text"
                         @doClickButton="onClickButton"
          />
        </div>

      </div>

    </div>

    <a-divider :dashed="true" style="margin: 40px 0 28px; border-color: var(--color-neutral-gray-300)"/>

    <div class="px-10">
      <VEmbedCode type="popup">
        <template #description>
          <p v-html="howToText"></p>
          <p class="mb-0">Note: the changes don't apply automatically. If you want to change the existing settings, replace your current code with the generated new code.</p>
        </template>
        <template #code>
          <code>
            {{ embedCode }}
          </code>
        </template>
      </VEmbedCode>
    </div>


  </div>
</template>

<script>
import VTextArea from "../../../VTextArea";
import '@/assets/less/embedButtons.less'
import VEmbedButtons from "../../../../layouts/VEmbedButtons";
import VEmbedCode from "../../../../layouts/VEmbedCode";
import {embedMixin} from "../../../../mixins/embed";
import {mapGetters} from "vuex";

const marginTopBottomDefault = 15;
const marginLeftRightDefault = 0;

export default {
  name: "VSharingPopup",
  props: {
    buttonType: {
      type: String,
      required: true,
    }
  },
  components: {VTextArea, VEmbedButtons, VEmbedCode},
  mixins: [embedMixin],
  beforeUpdate() {
    // Set the button shape as text for the cta and inline
    if (this.buttonType !== 'side-button') {
      this.shape = 'text'
    }

    if (this.buttonType === 'side-button') {
      this.size = 'lg'
    }
  },
  watch: {
    buttonType: function (type) {
      // Set the button size small for the inline button
      if (type === 'inline') {
        this.size = 'sm'
        this.isClosable = false
      }

      // Set the button size large for the cta
      if (type === 'cta') {
        this.size = 'lg'
        this.isClosable = false
      }

      // Set default margins
      this.marginTop = this.marginBottom = marginTopBottomDefault;
      this.marginRight = this.marginLeft = marginLeftRightDefault;
    },
  },
  data() {
    return {
      size: "lg",
      position: "right-middle",
      align: "center",
      text: "Leave feedback",
      isClosable: false,
      hasIcon: true,
      shape: "text",
      marginTop: marginTopBottomDefault,
      marginBottom: marginTopBottomDefault,
      marginLeft: marginLeftRightDefault,
      marginRight: marginLeftRightDefault,
      styles: {
        white: [
          {type: "white-text-black", className: "metasurvey-btn-white-text-black"},
          {type: "white-text-green", className: "metasurvey-btn-white-text-green"},
          {type: "white-text-blue", className: "metasurvey-btn-white-text-blue"},
          {type: "white-text-magenta", className: "metasurvey-btn-white-text-magenta"},
        ],
        color: [
          {type: "black", className: "metasurvey-btn-black"},
          {type: "green", className: "metasurvey-btn-green"},
          {type: "blue", className: "metasurvey-btn-blue"},
          {type: "magenta", className: "metasurvey-btn-magenta"},
        ],
        custom: [
          {type: "white-text-rainbow", className: "metasurvey-btn-white-text-rainbow"},
          {type: "rainbow", className: "metasurvey-btn-rainbow"},
        ],
      },
      currentStyle: "black",
    }
  },
  computed: {
    ...mapGetters('survey', ["survey"]),
    isSideButton: function () {
      return this.buttonType === "side-button"
    },
    isCta: function () {
      return this.buttonType === "cta"
    },
    isInline: function () {
      return this.buttonType === "inline"
    },
    labelColorStyle: function () {
      return this.shape === 'text' ? 'width: 55px;' : ''
    },
    embedCode: function () {
      if (this.buttonType === "side-button") {
        let script = document.createElement('script')
        script.type = 'text/javascript'

        let scriptOptions = `
          w.surveyId = '${this.survey._id}';
          w.buttonShape = '${this.shape}';
          w.buttonStyle = '${this.currentStyle}';
          w.buttonPosition = '${this.position}';
          w.buttonType = '${this.buttonType}';
        `

        if (this.shape === "text") {
          scriptOptions += `
          w.buttonText = '${this.text}';
          w.buttonSize = 'lg';
          w.buttonClosable = '${this.isClosable}';
          w.buttonHasIcon = '${this.hasIcon}';
          `
        }

        script.innerText = `
        (function (w, d) {
          ${scriptOptions}
          var s = d.createElement('script');
          s.src = '${this.$constants("EMBED_SCRIPT_URL")}';
          s.async = true;
          var e = document.getElementsByTagName('script')[0];
          e.parentNode.insertBefore(s, e);
        })(window, document);
        `

        return script.outerHTML.replaceAll('<br>', '')
      }

      if (this.buttonType === "cta") {
        let el = document.createElement('div')

        el.style.width = '100%'
        el.style.display = 'flex'
        el.style.boxSizing = 'border-box'
        el.style.justifyContent = this.align
        el.style.paddingTop = this.marginTop.toString() + 'px'
        el.style.paddingBottom = this.marginBottom.toString() + 'px'
        el.style.paddingLeft = this.marginLeft.toString() + 'px'
        el.style.paddingRight = this.marginRight.toString() + 'px'

        el.setAttribute('data-ms-popup', this.survey._id)
        el.setAttribute('data-ms-button-text', this.text)
        el.setAttribute('data-ms-button-type', this.buttonType)
        el.setAttribute('data-ms-button-style', this.currentStyle)
        el.setAttribute('data-ms-button-size', this.size)
        el.setAttribute('data-ms-button-shape', this.shape)
        el.setAttribute('data-ms-button-with-icon', this.hasIcon)

        return el.outerHTML + this.embedScript
      }

      if (this.buttonType === "inline") {
        let el = document.createElement('span')

        el.style.paddingLeft = this.marginLeft.toString() + 'px'
        el.style.paddingRight = this.marginRight.toString() + 'px'

        el.setAttribute('data-ms-popup', this.survey._id)
        el.setAttribute('data-ms-button-text', this.text)
        el.setAttribute('data-ms-button-type', this.buttonType)
        el.setAttribute('data-ms-button-style', this.currentStyle)
        el.setAttribute('data-ms-button-size', this.size)
        el.setAttribute('data-ms-button-shape', this.shape)
        el.setAttribute('data-ms-button-with-icon', this.hasIcon)

        return el.outerHTML + this.embedScript
      }

      return ""
    },
    howToText: function () {
      if (this.buttonType === "side-button") {
        return "<p class='mb-0'>This is your side button embed code. <b>Copy-paste</b> it in your page's HTML where you want your survey to appear.</p> <p class='mb-0'>For multiple pages, the easiest way to achieve that is to paste the code into the common section like <b>header</b> or <b>footer</b>.</p>"
      }

      if (this.buttonType === "cta") {
        return "This is your CTA button embed code. <b>Copy-paste</b> it in your page's HTML where you want your Call-To-Action button to appear."
      }

      if (this.buttonType === "inline") {
        return "This is your inline button embed code. <b>Copy-paste</b> it in your content's HTML where you want your inline button to appear."
      }

      return ""
    },
  },
  methods: {
    onClickButton(type) {
      this.currentStyle = type;
    }
  }
}
</script>

<style lang="less" scoped>

</style>
